import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/seo"
import { minHeightForMenuPage, paddingForMenuPage } from "../defines";
import {
    List,
    Segment
} from "semantic-ui-react"

const EnvironmentPage = ({ mobile }) => (
    <>
        <SEO title="Environment" />
        <Segment
            textAlign="center"
            vertical
            inverted
            style={{ minHeight: minHeightForMenuPage(mobile), padding: paddingForMenuPage(mobile) }}
        >
            <List divided inverted relaxed="very">
                <List.Item as="a" onClick={() => navigate("/visualizations/environment/water-footprint")}>
                    <List.Content>
                        <List.Header>Water footprint comparison</List.Header>
                        <List.Description>Chart comparing water usage for plant/animal foods per unit of nutrition</List.Description>
                    </List.Content>
                </List.Item>
                <List.Item as="a" onClick={() => navigate("/visualizations/environment/material-sustainability-index")}>
                    <List.Content>
                        <List.Header>Materials sustainability index</List.Header>
                        <List.Description>Chart comparing the environment/societal impact of plant/animal materials</List.Description>
                    </List.Content>
                </List.Item>
            </List>
        </Segment>
    </>
);

export default EnvironmentPage;
